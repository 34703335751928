import news from '../../images'
import { about, blog, contact, guardian, homestay, workwithus, educationServices, supportServices } from "../../images";
// import about from "../../images/home/about.svg"

export const aboutusSection = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'About Us',
  headline: 'Who we are and what we do',
  description: 'Find out about the history of Study Links, our current team and our successes over the decades',
  buttonLabel: 'Find out more',

  buttonDestination: '/about',

  imgStart: false,
  img: about,
  alt: 'About us',
  dark: false,
  primary: false,
  darkText: true
};

export const guardianshipSection = {
  id: 'guardianship',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Guardianship',
  headline: 'Are you looking for a guardian?',
  description: 'A guardian is a person who has been chosen by a student’s parents to provide pastoral and academic care, ensuring the well-being and safety of the student whilst they are studying in the UK. A guardian acts on behalf of parents at such times as is reasonably required.',
  buttonLabel: 'Find out more',

  buttonDestination: '/guardianship',

  imgStart: true,
  img: guardian,
  alt: 'Guardianship',
  dark: true,
  primary: true,
  darkText: false
};

export const homestaySection = {
  id: 'homestay',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Homestay',
  headline: 'Are you looking for a homestay?',
  description: 'Every year thousands of young people studying in the UK become part of a new family – some for a short time, others for a little longer. But each will create memories, have positive experiences, and build lasting bonds thanks to the amazing homestay families who step in as the students’ home away from home.',
  buttonLabel: 'Find out more',
  buttonDestination: '/homestay',
  imgStart: false,
  img: homestay,
  alt: 'homestay',
  dark: false,
  primary: false,
  darkText: true
};

export const educationServicesSection = {
  id: 'educationServices',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Education Services',
  headline: 'Are you looking for educational support?',
  description: 'Study Links provide services to support, enhance and accelerate student’s academic progress.  Our team work closely with families to tailor the right support package so students can reach their full potential.',
  buttonLabel: 'Find out more',

  buttonDestination: '/educationServices',

  imgStart: true,
  img: educationServices,
  alt: 'Guardianship',
  dark: true,
  primary: true,
  darkText: false
};

export const supportServicesSection = {
  id: 'supportServices',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Support Services',
  headline: 'Do you need support to confidently live abroad?',
  description: 'We help students and their families with every step of the transition to move overseas: from immigration and relocation services, to cultural and etiquette training.',
  buttonLabel: 'Find out more',
  buttonDestination: '/supportServices',
  imgStart: false,
  img: supportServices,
  alt: 'homestay',
  dark: false,
  primary: false,
  darkText: true
};

export const courseSection = {
  id: 'course',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'UK Course Applications',
  headline: 'Applying for British school or university?',
  description: 'Since 1998, we have helped international students make successful applications to world leading British universities and the best boarding schools in the UK. We guide and advise students and parents so that you are well prepared to make informed decisions about institution and subject choices.',
  buttonLabel: 'UK education system: Explained',
  buttonLabel2: 'Secondary schools',
  buttonLabel3: 'Further education (FE) colleges',
  buttonLabel4: 'Universities',
  buttonLabel5: 'English language schools',
  buttonLabel6: 'Summer schools',
  buttonLabel7: 'Group study programmes',
  imgStart: true,
  img: blog,
  alt: 'Blog',
  dark: true,
  primary: true,
  darkText: false
};

export const newsSection = {
  id: 'news',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'News',
  headline: "Keep up to date with StudyLinks' response to COVID19",
  description: '',
  buttonLabel: 'Latest news',
  imgStart: false,
  img: news,
  alt: 'Car',
  dark: false,
  primary: false,
  darkText: true
};

export const blogSection = {
  id: 'blog',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Blog',
  headline: 'Read about the latest goings-on at Study Links',
  description: 'See what our students have to say about their experiences so far!',
  buttonLabel: 'Read the blog',
  buttonDestination: '/blog',
  imgStart: true,
  img: blog,
  alt: 'blog',
  dark: true,
  primary: true,
  darkText: false
};

export const workSection = {
  id: 'workwithus',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Working with us',
  headline: 'We are always looking for great people to work with!',
  description: 'Find out more about how we can work together!',
  buttonLabel: 'Find out more',
  buttonDestination: '/workwithus',
  imgStart: false,
  img: workwithus,
  alt: 'work with us',
  dark: false,
  primary: false,
  darkText: true
};

export const contactSection = {
  id: 'contact',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Contact',
  headline: 'Contact Us',
  description: 'Get in touch via social media or submit an enquiry',
  buttonLabel: 'Submit enquiry',
  buttonDestination: '/contact',
  imgStart: true,
  img: contact,
  alt: 'contact',
  dark: true,
  primary: true,
  darkText: false
};

export const covidSection = {
  id: 'covid',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'COVID19',
  headline: 'Customer statement re. Covid-19',
  description: 'At this time, Study Links remains open and operational, and will continue to deliver the high quality and reliable service you are used to.',
  description_2: 'For as long as the Government permits it, it is our intention to remain open throughout. We remain vigilant to ensure the ongoing safety and wellbeing of our students and our workforce during this difficult time and have implemented appropriate safe working measures. Our clients and partners can be assured that we are doing everything in our power to provide a continuous service through these challenging times.',
  description_3: 'We will continue to monitor the evolving situation closely on a daily basis and will update our internal processes and statement as and when circumsatnces warrant it. If you have further questions or concerns, please contact your company representative.',
  // buttonLabel: 'Find a guardian',
  dark: true,
  primary: true,
  darkText: false
};