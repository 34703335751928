import styled from "styled-components"
import { MdArrowDownward } from "react-icons/md"
import { TiArrowDownThick } from "react-icons/ti"
import React from "react"
import { hero1, hero2, hero3, hero4 } from "../../images/index"

export const HeroContainer = styled.div`
  /* background: #0c0c0c; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  /* background-color: #1b154a; */
  z-index: 0;

`

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`

export const ImgBg = styled.img`
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

export const HeroContent = styled.div`
  z-index: 2;
  max-width: 1200px;
  padding: 20px 24px;
  display: flex;
  margin: 20px;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
`

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: calc(10px + 3vw);
  text-align: center;
  margin: 0px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`

export const HeroP = styled.p`
  margin-top: 0.5vw;
  color: #fff;
  font-size: calc(2vw);
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`

export const HeroBtnWrapper = styled.div`
  margin-top: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ArrowDownward = styled(MdArrowDownward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowDownThick = styled(TiArrowDownThick)`
  margin-left: 8px;
  font-size: 20px;
`
