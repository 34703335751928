import React, {useState} from 'react'
import { ArrowDownward, ArrowDownThick, HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, HeroP, ImgBg } from './HeroElements'
import {ScrollButton} from '../../components/ButtonElement'
import Jello from 'react-reveal/Jello'
import Fade from 'react-reveal/Fade'
import CrossfadeImage from "react-crossfade-image";
import { story1, story2, story3, story4, hero1 } from "../../images/index";
import { Carousel } from "./HeroElements";
import BackgroundSlider from 'gatsby-image-background-slider'
import {useStaticQuery, graphql} from 'gatsby'

const HeroSection = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer>
      <BackgroundSlider 
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
              nodes {
                relativePath
                childImageSharp {
                  fluid (maxWidth: 4000, quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        style={{height:"100vh"}}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={4} // transition duration between images
        duration={5} // how long an image is shown
      />
      <HeroContent>
        <Fade>
          <HeroH1>Excellence You Need, Service You Deserve </HeroH1>
          <HeroP>
            <Fade>
              Opening doors to British Education since 1998
            </Fade>
          </HeroP>
          <Jello>
            <HeroBtnWrapper>
              <ScrollButton 
                to="videoSection" 
                smooth={true}
                onMouseEnter={onHover} 
                onMouseLeave={onHover}
                primary = 'true'
                dark='true'
              >
                Get started {hover ? <ArrowDownThick /> : <ArrowDownward />}
              </ScrollButton>
            </HeroBtnWrapper>
          </Jello>
        </Fade>
      </HeroContent>
    </HeroContainer>
      
  )
}

export default HeroSection
