import Video from "../../components/Video/Video";
import React, {useState} from 'react'
import { VideoSectionContainer, VideoContainer, Welcome, WelcomeTitle, WelcomeText, ButtonWrapper, VideoSectionWrapper } from "./elements";
import { ScrollButton } from "../../components/ButtonElement";
import { ArrowDownThick, ArrowDownward } from "../Hero/HeroElements";
import WelcomeVideo from '../../video/Welcome.mp4'

const VideoSection = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }
  return (
    <VideoSectionContainer id='videoSection'>
      <VideoSectionWrapper>
        <Welcome>
          <WelcomeTitle>
            Welcome to Study Links
          </WelcomeTitle>
          <WelcomeText>
          Study Links works alongside the best schools and other educational institutions in the UK to ensure the education provided is accessible and beneficial to the individuals under our care.
          </WelcomeText>
          <WelcomeText>
          There are many routes to success - to ensure that we meet each student’s unique needs, we offer an array of high-quality services throughout the UK.
          </WelcomeText>
        </Welcome>
        <VideoContainer>
          <Video videoSrcURL={WelcomeVideo} videoTitle="Welcome to Studylinks"/>
        </VideoContainer>
      </VideoSectionWrapper>
        <ButtonWrapper>
          <ScrollButton 
            to="about" 
            smooth={true}
            onMouseEnter={onHover} 
            onMouseLeave={onHover}
            primary = 'true'
            dark='true'
          >
            Find out more {hover ? <ArrowDownThick /> : <ArrowDownward />}
          </ScrollButton>
        </ButtonWrapper>
    </VideoSectionContainer>
  )
}

export default VideoSection