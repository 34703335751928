import styled from 'styled-components'
import { Link } from "gatsby";
import {Link as LinkS} from "react-scroll"
import { AnchorLink as LinkA} from "gatsby-plugin-anchor-links";

export const CentreWrapper = styled.div `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const Button = styled(Link) `
  border-radius: 50px;
  background: #ec008c;
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  font-size: 16px;
  color: ${({dark}) => (dark ? '#fff' : '#fff')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #00aeef;
    text-decoration: none;
    color: ${({dark}) => (dark ? '#fff' : '#fff')};

  }

`

export const GreenButton = styled.a `
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  font-size: 16px;
  color: ${({dark}) => (dark ? '#fff' : '#fff')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  max-width: 300px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #00aeef;
    text-decoration: none;
    color: ${({dark}) => (dark ? '#fff' : '#fff')};

  }

`
export const RealButton = styled.button `
  border-radius: 50px;
  background: #ec008c;
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  font-size: 16px;
  color: ${({dark}) => (dark ? '#fff' : '#fff')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #00aeef;
    text-decoration: none;
    color: ${({dark}) => (dark ? '#fff' : '#fff')};

  }
`

export const ScrollButton = styled(LinkS) `
  border-radius: 50px;
  background: #ec008c;
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  font-size: 16px;
  color: ${({dark}) => (dark ? '#fff' : '#fff')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #00aeef;
    text-decoration: none;
    color: ${({dark}) => (dark ? '#fff' : '#fff')};

  }

`
export const AnchorButton = styled(LinkA) `
  border-radius: 50px;
  background: #ec008c;
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  font-size: 16px;
  color: ${({dark}) => (dark ? '#fff' : '#fff')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #00aeef;
    text-decoration: none;
    color: ${({dark}) => (dark ? '#fff' : '#fff')};

  }

`

// export const Button = styled(Link) `
//   border-radius: 50px;
//   background: ${({primary}) => (primary ? '#00aeef' : '#ec008c')};
//   white-space: nowrap;
//   padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
//   font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
//   color: ${({dark}) => (dark ? '#fff' : '#fff')};
//   outline: none;
//   border: none;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: all 0.2s ease-in-out;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: ${({primary}) => (primary ? '#fff' : '#fff')};
//     color: #010606
//   }

// `