import React from 'react'
import { AnchorButton } from '../../components/ButtonElement'
import { BtnWrap, Column1, Column2, Heading, Img, ImgWrap, InfoContainer, InfoRow, InfoWrapper, Subtitle, TextWrapper, TopLine } from './InfoElements'

const InfoSection = ({lightBg,id,imgStart,topLine,lightText,headline,darkText,description,buttonLabel,img,alt, primary, dark, dark2, description_2, description_3, buttonLabel2, buttonLabel3, buttonLabel4, buttonLabel5, buttonLabel6, buttonLabel7, buttonDestination, buttonDestination2, buttonDestination3, buttonDestination4, buttonDestination5}) => {
  console.log(img)
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{description_2}</Subtitle>
                <Subtitle darkText={darkText}>{description_3}</Subtitle>
                <Column1>
                  {buttonLabel &&
                    <BtnWrap>
                      <AnchorButton 
                        to={buttonDestination}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel}</AnchorButton>
                    </BtnWrap>
                  }       
                  {buttonLabel2 &&
                    <BtnWrap>
                      <AnchorButton 
                        to={buttonDestination2}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel2}</AnchorButton>
                    </BtnWrap>
                  } 
                  {buttonLabel3 &&
                    <BtnWrap>
                      <AnchorButton 
                        to={buttonDestination3}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel3}</AnchorButton>
                    </BtnWrap>
                  }   
                  {buttonLabel4 &&
                    <BtnWrap>
                      <AnchorButton 
                        to={buttonDestination4}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel4}</AnchorButton>
                    </BtnWrap>
                  } 
                </Column1>
                <Column2>
                  {buttonLabel5 &&
                    <BtnWrap>
                      <AnchorButton 
                        to={buttonDestination5}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel5}</AnchorButton>
                    </BtnWrap>
                  } 
                  {buttonLabel6 &&
                    <BtnWrap>
                      <AnchorButton 
                        to='home'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel6}</AnchorButton>
                    </BtnWrap>
                  } 
                  {buttonLabel7 &&
                    <BtnWrap>
                      <AnchorButton 
                        to='home'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                      >
                        {buttonLabel7}</AnchorButton>
                    </BtnWrap>
                  } 
                </Column2>
                
                
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection
