import styled from "styled-components";

export const VideoSectionContainer = styled.div`
  height: calc(1200px - 30vw);
  background-color: #1b154a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-height: 800px){
    height: 100vh;
  }

  @media (max-width: 400px) {
    height: 1000px;
  }
  @media (max-width: 325px) {
    height: 1200px;
  }
  
`

export const VideoSectionWrapper = styled.div `
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  
  @media (max-width: 1040px){
    flex-wrap: wrap;

  }
`

export const VideoContainer = styled.div `
  align-items: center;
  margin: 40px;
  max-width: 100%;
`

export const Welcome = styled.div `
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WelcomeTitle = styled.h1 `
`

export const WelcomeText = styled.p `
  text-align: center;
  @media (min-width: 2000px){
    font-size: 20px;
  }
`

export const ButtonWrapper = styled.div `
  max-width: 200px;
`