import React, { useState } from "react";
import Footer from "../components/Footer";
import MobileNavbar from "../components/MobileNavBar";
import ScrollNavbar from "../components/Navbar/ScrollNavbar";
import SEO from "../components/seo";
import { HomepageTestimony } from '../components/Testimonials';
import { QRCodePopup } from "../components/WeChatPopup";
import HeroSection from "../containers/Hero";
import InfoSection from '../containers/InfoSection';
import { aboutusSection, blogSection, contactSection, educationServicesSection, guardianshipSection, homestaySection, supportServicesSection, workSection } from '../containers/InfoSection/Data';
import VideoSection from "../containers/VideoSection";
import '../styles/global.css';

export default function Home(props) {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
        <SEO title="Home | Guardianship | British Education | Homestay" description="Study Links works alongside the best schools & other educational institutions in the UK ensuring the education provided is accessible and beneficial to the individuals under our care."/>
        <MobileNavbar isOpen={isOpen} toggle={toggle}/>
        <ScrollNavbar toggle={toggle}/>
        <QRCodePopup/>

        {/* <VerticalIcons /> */}
        <HeroSection/>
        <VideoSection/>
        {/* <Menu /> */}
        <InfoSection {...aboutusSection}/>
        <InfoSection {...guardianshipSection}/>
        <InfoSection {...homestaySection}/>
        <InfoSection {...educationServicesSection}/>
        <InfoSection {...supportServicesSection}/>
        <InfoSection {...blogSection}/>
        <InfoSection {...workSection}/>
        <InfoSection {...contactSection}/>
        <HomepageTestimony />
        <Footer />
    </>
   
  )
}

